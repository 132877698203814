import React from "react"
import { Link } from "gatsby"

import WIheader from '../assets/WI-header.png';
import WIui from '../assets/WI-UI-kit.png';
import WInewMap from '../assets/WI-new-map.png';
import WImem from '../assets/WI-mem-credit.png';
import WInewSchedule from '../assets/WI-new-schedule.png';
import WIreservation from '../assets/WI-reservation.png';
import WIweek from '../assets/WI-week-schedule.png';
import WIaccount from '../assets/WI-account.png';
import WIcreate from '../assets/WI-create-account.png';
import WIConfirmed from '../assets/Wi-confirmed.png';
import ArrowRight from '../assets/arrow-right.svg';

import wireframe1 from '../assets/WI-wireframe1.png';
import wireframe2 from '../assets/WI-wireframe2.png';
import wireframe3 from '../assets/WI-wireframe3.png';
import wireframe4 from '../assets/WI-wireframe4.png';
import wireframe5 from '../assets/WI-wireframe5.png';
import wireframe6 from '../assets/WI-wireframe6.png';

import Layout from "../components/layout"
import '../components/home.scss';
import '../components/projects.scss';

export default function Home() {
  return (
    <Layout>
      <section className="section-no-margin">
        <div className="row">
          <div className="align-center section-line">
            <img className="col-10 margin-btm-lg WI-header col-100" src={WIheader} alt="Web Integrations schedule, buy page and mobile view" />
            <div className="align-left margin-top-neg">
              <h1 className="header--medium margin-btm-xxs">Mariana Tek - Web Integrations</h1>
              <div className="flex flex-break-sm">
                <div className="col-7 divider-coral col-100 mobile-margin-btm-xs">
                  <p>I redesigned one of Mariana’s 3 main products from top to bottom. Creating wireframes, designs and doing user testing along the way to ensure we were building the best product for our users. I built a UI kit and worked with developers to build out these components in Storybook, before handing the project off to one of my designers. I also helped prep the sales team and talked through the exciting changes to Web Integrations with existing and potential clients.</p>
                </div>
                <div className="flex flex-col col-5 col-100">
                  <div className="margin-btm-xxs">
                    <label className="header-label">Role:</label>
                    <span className="text-sm">Director of Design</span>
                  </div>
                  <div className="list-sm list-reponsibilities">
                    <label className="header-label">Responsibilities:</label>
                    <ul className="flex-grid-list">
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span>UI / UX (wireframes etc..)</span>
                      </li>
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span>Prototyping</span>
                      </li>
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span>Design</span>
                      </li>
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span>Coordination / handoff</span>
                      </li>
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span>Assisted with marketing / sales</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="row-projects flex-break-sm">
            <div className="column column1 col-4 row-paddingflex flex-center row-padding-md flex-break-sm col-100 mobile-margin-btm-sm">
              <h3 className="header--small">Wireframes and Testing</h3>
              <p>Web Integrations had been in use for quite some time, so luckly we had a lot of insight from clients and their customers about what worked and what didn’t. Unfortunately, there were a lot of flows and pages that needed a lot of rework. I started the process of improving Web Integrations by making wireframes and doing user testing to validate my ideas.</p>
            </div>
            <div className="column column2 col-8 col-100 mobile-margin-btm-xl">
              <div className="flex flex-grid flex-grid-3">
                <div className="project">
                  <img className="withShadow project-with-border" src={wireframe1} alt="Wireframe - picking a spot in class for yourself" />
                </div>
                <div className="project">
                  <img className="withShadow project-with-border" src={wireframe2} alt="Wireframe - picking a spot in class for a guest" />
                </div>
                <div className="project">
                  <img className="withShadow project-with-border" src={wireframe4} alt="Wireframe - payment options page" />
                </div>
                <div className="project">
                  <img className="withShadow project-with-border" src={wireframe3} alt="Wireframe - adding a payment option" />
                </div>
                <div className="project">
                  <img className="withShadow project-with-border" src={wireframe5} alt="Wireframe - payment options page with valid payment" />
                </div>
                <div className="project">
                  <img className="withShadow project-with-border" src={wireframe6} alt="Wireframe - picking a spot in class for a guest" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-fullwidth process-bg">
        <div className="section-internal">
          <div className="align-center margin-btm-md">
            <h3 className="header--small">Process</h3>
          </div>
          <div className="flex-grid-5">
            <div>
              <h4 className="header--smallest">Discovery / Interviews</h4>
              <p>Gathering complaints, support tickets, old testing sessions etc... and where relevant, talking to clients about what they wanted and needed. For flows that needed to be redone, functionality examples were found and discussed.</p>
              <span className="text-light">My User Researcher gathered old information and the rest I did myself</span>
            </div>
            <div>
              <h4 className="header--smallest">Wireframes / Prototypes</h4>
              <p>Once we decided how to iteratively tackle this redesign, I build out wireframes. To show flows and enable testing, I then made prototypes.</p>
              <span className="text-light">Myself</span>
            </div>
            <div>
              <h4 className="header--smallest">Qualitative Testing</h4>
              <p>Using the prototypes, I worked with my User Researcher to test each of the flows with current and potential clients.</p>
              <span className="text-light">Majority of the time my User Researcher did this work</span>
            </div>
            <div>
              <h4 className="header--smallest">Design</h4>
              <p>Once we knew that the functionality and the flows in the wireframes were what users expected, I designed each page in mobile, tablet and desktop sizes, also insuring that the product felt much like our other products. Finally, I built a UI kit for consistency.</p>
              <span className="text-light">Myself</span>
            </div>
            <div>
              <h4 className="header--smallest">Coordination with Devs / Building of Compontnets</h4>
              <p>Because this, like many of the projects I worked on at Mariana, was very circular, I worked closely with the dev team throughout the design and UX processes and also as they built out the site and components in Storybook.</p>
              <span className="text-light">Myself</span>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="row-projects flex-break-sm">
            <div className="column column1 col-8 row-padding-md row-padding-md-mob-none col-100 mobile-margin-btm-sm">
              <div className="project section-no-margin">
                <img className="withShadow project-with-border" src={WIui} alt="part of UI kit for web integrations project" />
              </div>
            </div>
            <div className="column column2 col-4 flex flex-center col-100">
              <h3 className="header--small">Building Components</h3>
              <p>We built this project out iteratively and, along the way, built relevant components out in Storybook. I also built all the components in Figma, for the designers and developers to reference and to enable the Storybook work.</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="row-projects flex-break-sm">
            <div className="column column1 col-5 row-padding-md row-padding-md-mob-none col-100">
              <div className="margin-btm-md">
                <h3 className="header--small">Final & Future Designs</h3>
                <p>Below is a sampling of the many pages I designed as well as a few that focused on future iterations. Because we were building for so many different studios, who all had such different brands, my thought for future iterations was to give studios the ability to pick and choose templates / styles, to better fit their brand.</p>
              </div>
              <div className="project">
                <img className="withShadow project-with-border" src={WIConfirmed} alt="confirmed page after reservation is made" />
              </div>
              <div className="project">
                <img className="withShadow project-with-border" src={WInewMap} alt="future pick a spot map" />
              </div>
              <div className="project">
                <img className="withShadow project-with-border" src={WInewSchedule} alt="future schedule page" />
              </div>
            </div>
            <div className="column column2 col-7 col-100">
              <div className="project">
                <img className="withShadow project-with-border" src={WIweek} alt="week view of classes" />
              </div>
              <div className="row-projects">
                <div className="column column1 col-7 row-padding-md">
                  <div className="project">
                    <img className="withShadow project-with-border" src={WIreservation} alt="Reservation page" />
                  </div>
                  <div className="project">
                    <img className="withShadow project-with-border" src={WIcreate} alt="Create an account page" />
                  </div>
                </div>
                <div className="column column2 col-7">
                  <div className="project">
                    <img className="withShadow project-with-border" src={WImem} alt="Buy page - to purchase memeberships, credits or gift cards" />
                  </div>
                  <div className="project">
                    <img className="withShadow project-with-border" src={WIaccount} alt="Account page" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-fullwidth">
        <div className="footer-projects footer-WAPO">
          <div className="wrapper-project-footer flex-center column">
            <div className="section-internal">
              <div className="flex flex-row space-between">
                <div>
                  <span className="next-previous">Next Project</span>
                  <Link className="" to="/WAPO/">
                    <h3 className="header--medium">Washington Post - 2016 Election</h3>
                  </Link>
                  <span className="description">Design & UX</span>
                </div>
                <Link className="" to="/WAPO/">
                  <img className="next-arrow" src={ArrowRight} alt="arrow" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
